import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LifeCycles, registerApplication, start } from 'single-spa';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './contexts/AwsCognitoContext';
import { AuthControl } from 'components/authControl';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <AuthControl>
    <AuthProvider>
      <HelmetProvider>
        <SettingsProvider>
          <CollapseDrawerProvider>
            <App />
          </CollapseDrawerProvider>
        </SettingsProvider>
      </HelmetProvider>
    </AuthProvider>
  </AuthControl>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function registerSPA(name: string, path: string, isDefault: boolean = false): void {
  registerApplication(
    `spa-${name}`,
    (): Promise<LifeCycles> => (window as any).System.import(`@bou/spa-${name}`),
    (location) => location.pathname.startsWith(path)
  );
}

registerSPA('login', '/auth');
registerSPA('order', '/orders');
registerSPA("users", "/users");
registerSPA("hub", "/hub");
registerSPA("management", "/management");
registerSPA("metrics", "/metrics");
registerSPA("report", "/report");
start();
